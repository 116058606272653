export default function Debouncer() {
  return {
    timer: null,
    clear() {
      clearTimeout(this.timer)
    },
    debounce(debounceTime, callback) {
      this.clear()
      this.timer = setTimeout(callback, debounceTime)
    },
  }
}
