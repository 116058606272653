import MotionScroll from 'motion-scroll'
import { easeInOutCubic } from 'es6-easing'

const MOBILE_BREAKPOINT = 600
let lastScrollYPosition = null

export function enableDocumentScroll() {
  ;[document.documentElement, document.body].forEach(el => {
    el.style.overflow = ''
    el.style.height = ''
  })
  ;[...document.querySelectorAll('.js-site-scroll-adapt')].forEach(container => {
    container.style.transform = ''
  })
  if (lastScrollYPosition !== null) window.scrollTo(0, lastScrollYPosition)
  lastScrollYPosition = null
}

export function disableDocumentScroll() {
  const overflow = 'hidden'
  let isStylingBody = false
  let height = ''
  let siteTransform = ''

  if (window.innerWidth < MOBILE_BREAKPOINT) {
    lastScrollYPosition = window.scrollY
    isStylingBody = true
    height = '100%'
    siteTransform = `translateY(${-lastScrollYPosition}px`
  }
  ;[document.documentElement, document.body].forEach(el => {
    if (el === document.body && !isStylingBody) return

    el.style.overflow = overflow
    el.style.height = height
  })
  ;[...document.querySelectorAll('.js-site-scroll-adapt')].forEach(container => {
    container.style.transform = siteTransform
  })
}

export function preventScrollRestoration() {
  if (!process.browser) return
  if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
}

export function unstickScrollFromBottom() {
  if (!process.browser) return
  if (window.pageYOffset + window.innerHeight === document.documentElement.scrollHeight) {
    window.scrollBy(0, -1)
  }
}

export function smoothScrollTo(params) {
  if (typeof params.scrollTo === 'undefined') return

  const defaultParams = {
    scrollTo: 0,
    speed: 1000, // pixels per second
    duration: null, // will overwrite speed if specified
    easing: easeInOutCubic,
    minScrollTime: 0.3,
    maxScrollTime: 1,
    force: true,
  }
  const options = { ...defaultParams, ...params }
  if (options.duration) {
    options.minScrollTime = options.maxScrollTime = options.duration
    delete options.duration
  }
  MotionScroll.scroll(options)
}

export function scrollSmoothlyToElement(element, params = {}) {
  if (!element) return
  const scrollTo = getElementScrollToPosition(element, params)

  if (!params.isScrollingUpOnly || window.pageYOffset > scrollTo) {
    smoothScrollTo({ scrollTo, ...params })
  }
}

export function scrollToElement(element, params = {}) {
  if (!element) return
  const scrollTo = getElementScrollToPosition(element, params)
  if (!params.isScrollingUpOnly || window.pageYOffset > scrollTo) window.scrollTo(0, scrollTo)
}

function getElementScrollToPosition(element, params) {
  const offset = params.offset || 0
  const headerHeight =
    params.isIncludingHeader !== false
      ? document.querySelector('.site-header')?.offsetHeight || getAccurateHeaderHeight()
      : 0
  return window.pageYOffset + element.getBoundingClientRect().top - headerHeight + offset
}

function getAccurateHeaderHeight() {
  return window.innerWidth >= 800 ? 80 : 70
}
