import { InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'

export default function ({ app }) {
  const language = app.i18n.locale
  return {
    defaultHttpLink: false,
    link: createUploadLink({
      uri: process.env.GRAPHQL_API_URL || 'http://localhost:1337/graphql',
      fetch,
      headers: {
        'Accept-Language': language || 'fr',
      },
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Report: {
          // Ensure objects can be identified with `client.cache.identify({ __typename: typeName, id })`
          keyFields: ['id'],
        },
      },
    }),
  }
}
