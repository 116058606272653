import { isObject } from 'acces-impot-utils/lib/validation'
import modelSettings from 'acces-impot-settings-report'

const DEFAULT_GENDER = modelSettings.genders.m

export function personalizeTranslation({
  key,
  personType,
  personGender,
  fn = '$t',
  count = 1,
  options,
}) {
  let finalKey = key
  let msg = this[fn](key)
  const deepKeys = [personType, 'other', personGender || '-', DEFAULT_GENDER]

  for (const k of deepKeys) {
    if (!isObject(msg)) break

    if (k && msg.hasOwnProperty(k)) {
      msg = msg[k]
      finalKey += `.${k}`
    }
  }
  const signature = fn === '$tc' ? [finalKey, count, options] : [finalKey, options]
  return this[fn](...signature)
}

export function mergeLocaleMessages(i18n, messages) {
  for (const locale in messages) i18n.mergeLocaleMessage(locale, messages[locale])
}

export function getTranslationKeyForAdmin(key) {
  const isTranslationDefined = key => this.$t(key) !== key
  const [, parentKey, childKey] = key.match(/^(.+)\.([^.]+)$/) || []

  return isTranslationDefined(`${parentKey}.admin.${childKey}`)
    ? `${parentKey}.admin.${childKey}`
    : key
}

export function personalizeStringOrJson(rawText, gender) {
  let text = ''
  try {
    text = JSON.parse(rawText)
  } catch (_err) {}

  if (text === '') return rawText

  if (text && typeof text === 'object' && ['m', 'f'].includes(gender)) {
    return text[gender] || rawText
  }
}
