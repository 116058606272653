import { registerChatwootEvents } from '../chatwoot'

const BASE_URL = 'https://app.chatwoot.com'

/**
 * @see https://www.chatwoot.com/hc/user-guide/articles/1677587234-how-to-send-additional-user-information-to-chatwoot-using-sdk
 */
export default function (language) {
  ;(function (d, t) {
    const g = d.createElement(t),
      s = d.getElementsByTagName(t)[0]
    g.src = BASE_URL + '/packs/js/sdk.js'
    g.defer = true
    g.async = true
    s.parentNode.insertBefore(g, s)
    g.onload = function () {
      // Has been reported as sometimes undefined (might be blocked)
      if (!window.chatwootSDK) return

      window.chatwootSDK.run({
        websiteToken: 'ryUb6J6AG465R1QDi5KjrXfT',
        baseUrl: BASE_URL,
        locale: language,
      })
      registerChatwootEvents()
    }
  })(document, 'script')
}
