<template>
  <div
    :class="[
      $style.button,
      {
        [$style.loading]: isLoading,
        [$style.iconBefore]: !!$slots.iconBefore,
        [$style.iconAfter]: !!$slots.iconAfter,
      },
    ]"
    v-on="$listeners"
  >
    <slot v-if="$slots.iconBefore" name="iconBefore" />
    <span :class="textClasses"><slot /></span>
    <slot v-if="$slots.iconAfter" name="iconAfter" />
  </div>
</template>

<script>
export default {
  name: 'IconButton',

  props: {
    textClass: {
      type: String,
      default: '',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    textClasses() {
      const cls = [this.$style.text]
      if (this.textClass) cls.push(this.textClass)

      return cls
    },
  },
}
</script>

<style lang="scss" module>
@mixin cta-spinner-properties($size, $color: currentColor) {
  @include spinner($size, $color, 1px);
  display: inline;
  top: calc(50% - #{floor($size / 2) + 1});
  left: $size;
  margin-left: floor($size / 2) * -1;
  transition-duration: 0s;
}

.button {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border: 1px solid $color-light-grey-2;
  border-radius: $border-radius;
  padding: 10px 16px 10px 16px;
  cursor: pointer;

  &:hover {
    border-color: $color-grey-5;
  }

  &.loading:global {
    &:before,
    &:hover:before,
    &:focus:before {
      @include cta-spinner-properties(22px);

      z-index: 50;
    }
  }
}

.iconBefore {
  padding-right: 20px;
}

.iconAfter {
  padding-left: 20px;
}

.loading {
  > *:not(.text) {
    opacity: 0;
  }
}

.text {
  display: inline-block;

  .iconBefore & {
    padding-left: 10px;
  }

  .iconAfter & {
    padding-right: 10px;
  }
}
</style>
