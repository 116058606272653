<template>
  <component :is="tag" class="link-underline" v-bind="componentProps" v-on="$listeners">
    <div class="link-underline__inner">
      <slot />
      <div class="link-underline__line" />
    </div>
  </component>
</template>

<script>
import Link from './Link.vue'

const LINK_COMPONENT = 'LinkComponent'

export default {
  name: 'LinkUnderline',
  components: {
    LinkComponent: Link,
  },

  props: {
    tag: {
      type: String,
      default: LINK_COMPONENT,
    },

    to: {
      type: [String, Object],
    },

    handleClick: {
      type: Function,
    },
  },

  computed: {
    componentProps() {
      return this.tag === LINK_COMPONENT
        ? {
            to: this.to,
            handleClick: this.handleClick,
            hasStyle: false,
          }
        : {}
    },
  },
}
</script>

<style lang="scss" scoped>
$transition-margin: 4px;

.link-underline {
  &__inner {
    position: relative;
    display: inline-block;
  }

  &__line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - #{$transition-margin * 2});
    height: 1px;
    background-color: currentColor;
    opacity: 0;
    transform: translateX(-50%);
    transition:
      opacity 0.3s,
      width 0.3s cubic-bezier(0.425, 0.18, 0.2, 0.985);
  }

  &:hover &__line {
    opacity: 1;
    width: 100%;
  }
}
</style>
