// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_nz5N2{position:relative;display:inline-block;white-space:nowrap;border:1px solid #ccc;border-radius:4px;padding:10px 16px;cursor:pointer}.button_nz5N2:hover{border-color:#8b8a88}.button_nz5N2.loading_nCs5O:before,.button_nz5N2.loading_nCs5O:focus:before,.button_nz5N2.loading_nCs5O:hover:before{content:\"\";position:absolute;background:none;top:0;left:0;border:1px solid;border-bottom:0;border-top-left-radius:11px;border-top-right-radius:11px;width:22px;height:11px;box-sizing:border-box;transform-origin:11px 11px;-webkit-animation:spinload 1s linear infinite;animation:spinload 1s linear infinite;display:inline;top:calc(50% - 12px);left:22px;margin-left:-11px;transition-duration:0s;z-index:50}.iconBefore_JeMtV{padding-right:20px}.iconAfter__tHam{padding-left:20px}.loading_nCs5O>:not(.text_1L1er){opacity:0}.text_1L1er{display:inline-block}.iconBefore_JeMtV .text_1L1er{padding-left:10px}.iconAfter__tHam .text_1L1er{padding-right:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_nz5N2",
	"loading": "loading_nCs5O",
	"iconBefore": "iconBefore_JeMtV",
	"iconAfter": "iconAfter__tHam",
	"text": "text_1L1er"
};
module.exports = ___CSS_LOADER_EXPORT___;
