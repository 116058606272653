import { ROUTE_NAMES } from '@/constants/route-names'

export const isAdminRouteComputed = {
  isAdminSection() {
    return (
      this.isAdminReportPage ||
      this.isAdminReportsPage ||
      this.isAdminTrainingPage ||
      this.isAdminRemunerationPage
    )
  },

  isAdminReportPage() {
    return this.$route.path?.startsWith(this.localePath({ name: ROUTE_NAMES.admin.report.index }))
  },

  isAdminReportsPage() {
    return this.$route.path?.startsWith(this.localePath({ name: ROUTE_NAMES.admin.reports }))
  },

  isAdminTrainingPage() {
    return this.$route.path?.startsWith(this.localePath({ name: ROUTE_NAMES.admin.training.index }))
  },

  isAdminRemunerationPage() {
    return this.$route.path?.startsWith(
      this.localePath({ name: ROUTE_NAMES.admin.remuneration.index })
    )
  },
}
