import modelSettings from 'acces-impot-settings-report'
import { PERSON_TYPES } from 'acces-impot-utils/lib/report'
import { personalizeTranslation, getTranslationKeyForAdmin } from '@/helpers/i18n'
import Input from '@/components/Form/Input.vue'
import Select from '@/components/Form/Select.vue'
import DateInput from '@/components/Form/DateInput.vue'
import Checkboxes from '@/components/Form/Checkboxes.vue'
import OverlayInfoQuestionCta from '@/components/Overlay/OverlayInfoQuestion/OverlayInfoQuestionCta.vue'
import {
  stringifyBoolean,
  replaceNullValuesWithStrings,
  getOptionsByReportModel,
  getYesNoOptions,
} from '../report-helpers'
import InfoSelfEmployed from './InfoSelfEmployed.vue'
import InfoRentalIncome from './InfoRentalIncome.vue'

export function getDefaultFormData(personType) {
  return {
    personType,
    completedQuestionCategories: '',
    drugInsuranceWhichOne: '',
    drugInsuranceSameSituation: '',
    drugInsurancePreviousSituation: '',
    drugInsurancePreviousSituationDate: '',
    drugInsuranceParticularSituations: '',
    solidarityTaxCreditWhoAsk: '',
    solidarityTaxCreditOwnOrRent: '',
    incomeSources: '',
    incomeSourcesOther: '',
    incomeSelfEmployed: '',
    incomeSelfEmployedWithExpenses: '',
    incomeSelfEmployedInstallmentPayments: '',
    rentalBuildingCount: '',
    rrspLast60Days: '',
    rrspContribution: '',
    rrspContributionLast60Days: '',
    rrspFhsaContribution: '',
    rrspWithdrawUnderHbpLlp: '',
    rrspWithdrawOtherSituation: '',
    childrenChildcareExpenses: '',
    childrenAdvancePayments: '',
    childrenActivityCosts: '',
    studentWereYou: '',
    studentScholarships: '',
    studentTuitonFeesToParent: '',
    studentWithdrawFromResp: '',
    studentRepaidInterestOnLoan: '',
    studentTuitionFeesPreviousYear: '',
    taxCreditsAskForOne: '',
    taxCreditsWhoIsDisabled: '',
    taxCreditsHomeSupportAdvancePayments: '',
    taxCreditsWholeYearSameAddress: '',
    taxCreditsStayedInRemoteAreaAllYear: '',
    taxCreditsBeginningOfStay: '',
    taxCreditsEndOfStay: '',
    taxCreditsMovingCloserToWork: '',
    telecommuting: '',
    medicalFees: '',
    donations: '',
    propertyOutsideCanada: '',
    associationContribution: '',
    associationContributionReimbursed: '',
    spouseOrChildSupportPayments: '',
    buyFirstHome: '',
    buyFirstHomeAlone: '',
    sellPrincipalResidence: '',
    sellOtherResidence: '',
    hadCryptoAssets: '',
    hadCryptoAssetsByEndOfTaxYear: '',
    hadGainOrLossFromCryptoAssets: '',
    financialAdvisor: '',
    investmentPlatformInterestLevel: '',
    preferredContactMethod: '',
    otherInformation: '',
  }
}

export function getLocaleKeyOfQuestion(questionKey) {
  return `account.report.questions.${questionKey}`
}

export function getLocaleKeyOfQuestionValue(questionKey, questionValue) {
  if (typeof questionValue === 'boolean') {
    return questionValue ? 'globals.yes' : 'globals.no'
  }
  const keysWithModel = {
    drugInsuranceWhichOne: 'drugInsuranceCoverages',
    drugInsurancePreviousSituation: 'drugInsuranceCoverages',
    drugInsuranceParticularSituations: 'drugInsuranceParticularSituations',
    solidarityTaxCreditWhoAsk: 'solidarityTaxCreditWhoAsk',
    solidarityTaxCreditOwnOrRent: 'solidarityTaxCreditOwnOrRent',
    incomeSources: 'incomeSources',
    rrspWithdrawUnderHbpLlp: 'withdrawUnderHbpLlp',
    taxCreditsAskForOne: 'taxCredits',
    studentTuitionFeesPreviousYear: 'yesNoDontKnow',
    investmentPlatformInterestLevel: 'investmentPlatformInterestLevel',
    preferredContactMethod: 'preferredContactMethod',
  }
  if (keysWithModel[questionKey]) {
    const modelKey = keysWithModel[questionKey]
    return `account.report.${modelKey}.${questionValue}`
  } else {
    return questionValue
  }
}

export function formatFormDataInternal(formDataInternal, data) {
  data = {
    ...formDataInternal,
    ...data,
  }
  const ifExists = (key, action) => {
    if (key in data) data[key] = action(data[key])
  }
  const getValueOrEmptyString = value => value || ''

  ifExists('drugInsuranceSameSituation', stringifyBoolean)
  ifExists('drugInsurancePreviousSituationDate', getValueOrEmptyString)
  ifExists('incomeSelfEmployed', stringifyBoolean)
  ifExists('incomeSelfEmployedWithExpenses', stringifyBoolean)
  ifExists('incomeSelfEmployedInstallmentPayments', stringifyBoolean)

  if (typeof data.rentalBuildingCount === 'number' && data.rentalBuildingCount > 0) {
    data.rentalBuildingCount = String(data.rentalBuildingCount)
  }

  ifExists('rrspLast60Days', stringifyBoolean)
  ifExists('rrspContribution', stringifyBoolean)
  ifExists('rrspContributionLast60Days', stringifyBoolean)
  ifExists('rrspFhsaContribution', stringifyBoolean)
  ifExists('rrspWithdrawOtherSituation', stringifyBoolean)
  ifExists('childrenChildcareExpenses', stringifyBoolean)
  ifExists('childrenAdvancePayments', stringifyBoolean)
  ifExists('childrenActivityCosts', stringifyBoolean)
  ifExists('studentWereYou', stringifyBoolean)
  ifExists('studentScholarships', stringifyBoolean)
  ifExists('studentTuitonFeesToParent', stringifyBoolean)
  ifExists('studentWithdrawFromResp', stringifyBoolean)
  ifExists('studentRepaidInterestOnLoan', stringifyBoolean)
  ifExists('taxCreditsHomeSupportAdvancePayments', stringifyBoolean)
  ifExists('taxCreditsWholeYearSameAddress', stringifyBoolean)
  ifExists('taxCreditsStayedInRemoteAreaAllYear', stringifyBoolean)
  ifExists('taxCreditsBeginningOfStay', getValueOrEmptyString)
  ifExists('taxCreditsEndOfStay', getValueOrEmptyString)
  ifExists('taxCreditsMovingCloserToWork', stringifyBoolean)
  ifExists('telecommuting', stringifyBoolean)
  ifExists('medicalFees', stringifyBoolean)
  ifExists('donations', stringifyBoolean)
  ifExists('propertyOutsideCanada', stringifyBoolean)
  ifExists('associationContribution', stringifyBoolean)
  ifExists('associationContributionReimbursed', stringifyBoolean)
  ifExists('spouseOrChildSupportPayments', stringifyBoolean)
  ifExists('buyFirstHome', stringifyBoolean)
  ifExists('buyFirstHomeAlone', stringifyBoolean)
  ifExists('sellPrincipalResidence', stringifyBoolean)
  ifExists('sellOtherResidence', stringifyBoolean)
  ifExists('hadCryptoAssets', stringifyBoolean)
  ifExists('hadCryptoAssetsByEndOfTaxYear', stringifyBoolean)
  ifExists('hadGainOrLossFromCryptoAssets', stringifyBoolean)
  ifExists('financialAdvisor', stringifyBoolean)

  formDataInternal = data
  replaceNullValuesWithStrings(formDataInternal)
  return formDataInternal
}

function getInfoCtaSettings(settings, personType, personGender) {
  const infoCta = {
    component: OverlayInfoQuestionCta,
    props: {
      topBarKey: settings.label,
      personType,
      personGender,
      isCheckbox: settings.isCheckbox,
    },
  }
  const key = 'reportFieldDetails.relevantQuestions'
  if (settings.details) infoCta.props.moreDetailsKey = `${key}.${settings.details}.details`
  if (settings.where) infoCta.props.whereToFindKey = `${key}.${settings.where}.where`
  if (settings.options) infoCta.props.translationOptions = settings.options

  return infoCta
}

function updateTaxCreditsLabelsByYear(labels, year) {
  const numYear = Number(year)
  if (numYear >= 2020) delete labels[modelSettings.taxCredits.renoVert]
}

function updateIncomeSourcesLabelsByYear(labels, year) {
  const numYear = Number(year)
  if (numYear < 2020 || numYear > 2022) delete labels[modelSettings.incomeSources.cerb]
}

export function getFields({
  reportYear: currentReportYear,
  isAdmin,
  personList,
  isApplicable = {},
} = {}) {
  const { personType, personGender } = this
  const { selfList, childrenList } = personList
  const oneYearLater = Number(currentReportYear) + 1
  const textPersonType =
    isAdmin && personType === PERSON_TYPES.self ? PERSON_TYPES.partner : personType

  const isApplicableForYear = {
    telecommuting: Number(currentReportYear) >= 2020 && Number(currentReportYear) < 2023,
    rrspFhsaContribution: Number(currentReportYear) >= 2023,
  }

  const personalizeText = (subKey, options) => {
    const key = `account.report.${subKey}`
    return personalizeTranslation.call(this, {
      key: isAdmin ? getTranslationKeyForAdmin.call(this, key) : key,
      personType: textPersonType,
      personGender,
      options,
    })
  }
  const getQuestionText = (subKey, options) => personalizeText(`questions.${subKey}`, options)
  const getQuestionTextWithYear = (subKey, options = {}) =>
    getQuestionText(subKey, { ...options, year: currentReportYear })

  const getInfoCtas = array => {
    return array.map(settings => getInfoCtaSettings(settings, personType, personGender))
  }
  const getOptionsByModel = modelKey => {
    return getOptionsByReportModel(modelKey, key => personalizeText(`${modelKey}.${key}`))
  }
  const getCheckboxLabelsByModel = (modelKey, exceptions = []) => {
    const labels = {}
    Object.values(modelSettings[modelKey]).forEach(key => {
      if (exceptions.includes(key)) return
      labels[key] = personalizeText(`${modelKey}.${key}`)
    })
    return labels
  }
  const YES_NO_OPTIONS = getYesNoOptions.call(this)
  const DRUG_INSURANCE_OPTIONS = getOptionsByModel('drugInsuranceCoverages')
  const WITHDRAW_UNDER_HBP_LLP_OPTIONS = getOptionsByModel('withdrawUnderHbpLlp')
  const INVESTMENT_PLATFORM_INTEREST_OPTIONS = getOptionsByModel('investmentPlatformInterestLevel')
  const PREFERRED_CONTACT_METHOD_OPTIONS = getOptionsByModel('preferredContactMethod')
  const taxCreditsLabels = getCheckboxLabelsByModel('taxCredits')
  updateTaxCreditsLabelsByYear(taxCreditsLabels, currentReportYear)

  const incomeSourcesLabels = getCheckboxLabelsByModel('incomeSources', ['retirement'])
  updateIncomeSourcesLabelsByYear(incomeSourcesLabels, currentReportYear)

  const fields = {
    [modelSettings.questionCategories.drugInsurance]: {
      titleLocalePath: 'account.report.questions.titles.drugInsurance',
      questions: {
        whichOne: {
          question: getQuestionText('drugInsuranceWhichOne'),
          components: [
            {
              is: Select,
              props: {
                id: 'drugInsuranceWhichOne',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: DRUG_INSURANCE_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('drugInsuranceWhichOne'),
                  details: 'drugInsuranceWhichOne',
                  where: 'drugInsuranceWhichOne',
                },
              ]),
            },
          ],
        },
        sameSituation: {
          question: getQuestionText('drugInsuranceSameSituation'),
          components: [
            {
              is: Select,
              props: {
                id: 'drugInsuranceSameSituation',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('drugInsuranceSameSituation'),
                  details: 'drugInsuranceSameSituation',
                  where: 'drugInsuranceSameSituation',
                },
              ]),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'drugInsuranceSameSituation',
                values: ['0'],
              },
              components: [
                {
                  is: Select,
                  props: {
                    id: 'drugInsurancePreviousSituation',
                    label: this.$t('account.report.previousSituation'),
                    options: DRUG_INSURANCE_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: 'account.report.previousSituation',
                      details: 'drugInsurancePreviousSituation',
                      where: 'drugInsurancePreviousSituation',
                    },
                  ]),
                },
                {
                  is: DateInput,
                  props: {
                    id: 'drugInsurancePreviousSituationDate',
                    label: this.$t('account.report.dateOfChange'),
                    specificYearOnly: Number(currentReportYear),
                  },
                  infoCta: getInfoCtas([
                    {
                      label: 'account.report.dateOfChange',
                      details: 'drugInsurancePreviousSituationDate',
                    },
                  ]),
                },
              ],
            },
          ],
        },
        particularSituation: {
          question: getQuestionText('drugInsuranceParticularSituations'),
          components: [
            {
              is: Checkboxes,
              modifiers: ['checkboxes'],
              props: {
                id: 'drugInsuranceParticularSituations',
                labels: getCheckboxLabelsByModel('drugInsuranceParticularSituations'),
                notApplicableKey: modelSettings.drugInsuranceParticularSituations.no,
                isShowingCheckedOnly: isAdmin,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('drugInsuranceParticularSituations'),
                  details: 'drugInsuranceParticularSituations',
                  where: 'drugInsuranceParticularSituations',
                  isCheckbox: true,
                },
              ]),
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.solidarityTaxCredit]: {
      titleLocalePath: 'account.report.questions.titles.solidarityTaxCredit',
      questions: {
        whoAskForIt: {
          question: getQuestionText('solidarityTaxCreditWhoAsk'),
          components: [
            {
              is: Select,
              props: {
                id: 'solidarityTaxCreditWhoAsk',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: getOptionsByModel('solidarityTaxCreditWhoAsk'),
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('solidarityTaxCreditWhoAsk'),
                  details: 'solidarityTaxCreditWhoAsk',
                  where: 'solidarityTaxCreditWhoAsk',
                },
              ]),
            },
          ],
        },
        ownOrRent: {
          question: getQuestionTextWithYear('solidarityTaxCreditOwnOrRent'),
          components: [
            {
              is: Select,
              props: {
                id: 'solidarityTaxCreditOwnOrRent',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: getOptionsByModel('solidarityTaxCreditOwnOrRent'),
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionTextWithYear('solidarityTaxCreditOwnOrRent'),
                  details: 'solidarityTaxCreditOwnOrRent',
                },
              ]),
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.incomeSources]: {
      titleLocalePath: 'account.report.questions.titles.incomeSources',
      questions: {
        whatWerethey: {
          question: getQuestionText('incomeSources'),
          components: [
            {
              is: Checkboxes,
              modifiers: ['checkboxes'],
              props: {
                id: 'incomeSources',
                labels: incomeSourcesLabels,
                notApplicableKey: modelSettings.incomeSources.none,
                isShowingCheckedOnly: isAdmin,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('incomeSources'),
                  details: 'incomeSources',
                  isCheckbox: true,
                },
              ]),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'incomeSources',
                values: value => value?.split(',').includes(modelSettings.incomeSources.other),
              },
              components: [
                {
                  is: Input,
                  props: {
                    id: 'incomeSourcesOther',
                    label: this.$t('globals.specify'),
                  },
                },
              ],
            },
            {
              isOpen: false,
              openOn: {
                id: 'incomeSources',
                values: value =>
                  value?.split(',').includes(modelSettings.incomeSources.rentalBuilding),
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('rentalBuildingCount'),
                  props: {
                    id: 'rentalBuildingCount',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: [
                      { value: undefined, text: '' },
                      ...Array.from(Array(15)).map((_, i) => {
                        const value = i + 1
                        return { value, text: value }
                      }),
                    ],
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('rentalBuildingCount'),
                      details: 'rentalBuildingCount',
                      where: 'rentalBuildingCount',
                    },
                  ]),
                  ...(!isAdmin
                    ? {
                        slot: {
                          is: InfoRentalIncome,
                          hasValueProp: true,
                        },
                      }
                    : {}),
                },
              ],
            },
          ],
        },
        incomeSelfEmployed: {
          question: getQuestionTextWithYear('incomeSelfEmployed'),
          components: [
            {
              is: Select,
              props: {
                id: 'incomeSelfEmployed',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'incomeSelfEmployed',
                values: ['1'],
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('incomeSelfEmployedWithExpenses'),
                  props: {
                    id: 'incomeSelfEmployedWithExpenses',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  ...(!isAdmin
                    ? {
                        slot: {
                          is: InfoSelfEmployed,
                          hasValueProp: true,
                        },
                      }
                    : {}),
                },
                {
                  is: Select,
                  question: getQuestionText('incomeSelfEmployedInstallmentPayments'),
                  props: {
                    id: 'incomeSelfEmployedInstallmentPayments',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('incomeSelfEmployedInstallmentPayments'),
                      details: 'incomeSelfEmployedInstallmentPayments',
                      options: { year: currentReportYear },
                    },
                  ]),
                },
              ],
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.rrsp]: {
      titleLocalePath: `account.report.questions.titles.${
        isApplicableForYear.rrspFhsaContribution ? 'rrspAndFhsa' : 'rrsp'
      }`,
      questions: {
        /**
         * @deprecated
         * It was split into "rrspContribution" and "rrspContributionLast60Days", but if a value
         * was previously entered by the customer, we keep showing the old question.
         */
        ...(typeof this.formData?.rrspLast60Days === 'boolean'
          ? {
              toRrspLast60Days: {
                question: getQuestionTextWithYear('rrspLast60Days', { oneYearLater }),
                components: [
                  {
                    is: Select,
                    props: {
                      id: 'rrspLast60Days',
                      label: this.$t('globals.select'),
                      isHidingLabelOnValue: true,
                      options: YES_NO_OPTIONS,
                    },
                    infoCta: getInfoCtas([
                      {
                        label: getQuestionTextWithYear('rrspLast60Days', { oneYearLater }),
                        details: 'rrspLast60Days',
                        where: 'rrspLast60Days',
                        options: { year: currentReportYear, oneYearLater },
                      },
                    ]),
                  },
                ],
              },
            }
          : {
              rrspContribution: {
                question: getQuestionTextWithYear('rrspContribution'),
                components: [
                  {
                    is: Select,
                    props: {
                      id: 'rrspContribution',
                      label: this.$t('globals.select'),
                      isHidingLabelOnValue: true,
                      options: YES_NO_OPTIONS,
                    },
                    infoCta: getInfoCtas([
                      {
                        label: getQuestionTextWithYear('rrspContribution'),
                        details: 'rrspContribution',
                        where: 'rrspContribution',
                        options: { year: currentReportYear },
                      },
                    ]),
                  },
                ],
              },
              rrspContributionLast60Days: {
                question: getQuestionTextWithYear('rrspContributionLast60Days', { oneYearLater }),
                components: [
                  {
                    is: Select,
                    props: {
                      id: 'rrspContributionLast60Days',
                      label: this.$t('globals.select'),
                      isHidingLabelOnValue: true,
                      options: YES_NO_OPTIONS,
                    },
                    infoCta: getInfoCtas([
                      {
                        label: getQuestionTextWithYear('rrspContributionLast60Days', {
                          oneYearLater,
                        }),
                        details: 'rrspContributionLast60Days',
                        where: 'rrspContributionLast60Days',
                        options: { year: currentReportYear, oneYearLater },
                      },
                    ]),
                  },
                ],
              },
            }),

        fhsaContribution: {
          question: getQuestionTextWithYear('rrspFhsaContribution'),
          isApplicable: () => isApplicableForYear.rrspFhsaContribution,
          components: [
            {
              is: Select,
              props: {
                id: 'rrspFhsaContribution',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionTextWithYear('rrspFhsaContribution'),
                  details: 'rrspFhsaContribution',
                  where: 'rrspFhsaContribution',
                  options: { year: currentReportYear },
                },
              ]),
            },
          ],
        },
        withdrawUnderHbpLlp: {
          question: getQuestionText('rrspWithdrawUnderHbpLlp'),
          components: [
            {
              is: Select,
              props: {
                id: 'rrspWithdrawUnderHbpLlp',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: WITHDRAW_UNDER_HBP_LLP_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('rrspWithdrawUnderHbpLlp'),
                  details: 'rrspWithdrawUnderHbpLlp',
                  where: 'rrspWithdrawUnderHbpLlp',
                },
              ]),
            },
          ],
        },
        withdrawOtherSituation: {
          question: getQuestionText('rrspWithdrawOtherSituation'),
          components: [
            {
              is: Select,
              props: {
                id: 'rrspWithdrawOtherSituation',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('rrspWithdrawOtherSituation'),
                  details: 'rrspWithdrawOtherSituation',
                },
              ]),
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.children]: {
      titleLocalePath: 'account.report.questions.titles.children',
      if: 'numberOfChildren',
      questions: {
        childcareExpenses: {
          question: getQuestionText('childrenChildcareExpenses'),
          components: [
            {
              is: Select,
              props: {
                id: 'childrenChildcareExpenses',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('childrenChildcareExpenses'),
                  details: 'childrenChildcareExpenses',
                },
              ]),
            },
          ],
        },
        advancePayments: {
          question: getQuestionText('childrenAdvancePayments'),
          components: [
            {
              is: Select,
              props: {
                id: 'childrenAdvancePayments',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('childrenAdvancePayments'),
                  details: 'childrenAdvancePayments',
                  where: 'childrenAdvancePayments',
                },
              ]),
            },
          ],
        },
        activityCosts: {
          question: getQuestionText('childrenActivityCosts'),
          components: [
            {
              is: Select,
              props: {
                id: 'childrenActivityCosts',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('childrenActivityCosts'),
                  details: 'childrenActivityCosts',
                  where: 'childrenActivityCosts',
                },
              ]),
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.student]: {
      titleLocalePath: 'account.report.questions.titles.student',
      questions: {
        wereYou: {
          question: getQuestionText('studentWereYou'),
          components: [
            {
              is: Select,
              props: {
                id: 'studentWereYou',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'studentWereYou',
                values: ['1'],
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('studentScholarships'),
                  props: {
                    id: 'studentScholarships',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('studentScholarships'),
                      details: 'studentScholarships',
                      where: 'studentScholarships',
                    },
                  ]),
                },
                {
                  is: Select,
                  question: getQuestionText('studentTuitonFeesToParent'),
                  props: {
                    id: 'studentTuitonFeesToParent',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('studentTuitonFeesToParent'),
                      details: 'studentTuitonFeesToParent',
                    },
                  ]),
                },
                {
                  is: Select,
                  question: getQuestionText('studentWithdrawFromResp'),
                  props: {
                    id: 'studentWithdrawFromResp',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('studentWithdrawFromResp'),
                      details: 'studentWithdrawFromResp',
                      where: 'studentWithdrawFromResp',
                    },
                  ]),
                },
              ],
            },
          ],
        },
        repaidInterestOnLoan: {
          question: getQuestionText('studentRepaidInterestOnLoan'),
          components: [
            {
              is: Select,
              props: {
                id: 'studentRepaidInterestOnLoan',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('studentRepaidInterestOnLoan'),
                  details: 'studentRepaidInterestOnLoan',
                  where: 'studentRepaidInterestOnLoan',
                },
              ]),
            },
          ],
        },
        tuitionFeesPreviousYear: {
          question: getQuestionText('studentTuitionFeesPreviousYear'),
          components: [
            {
              is: Select,
              props: {
                id: 'studentTuitionFeesPreviousYear',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: getYesNoOptions.call(this, [1, 0, 2]),
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('studentTuitionFeesPreviousYear'),
                  details: 'studentTuitionFeesPreviousYear',
                  where: 'studentTuitionFeesPreviousYear',
                },
              ]),
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.taxCredits]: {
      titleLocalePath: 'account.report.questions.titles.taxCredits',
      questions: {
        askForOne: {
          question: getQuestionText('taxCreditsAskForOne'),
          components: [
            {
              is: Checkboxes,
              modifiers: ['checkboxes'],
              props: {
                id: 'taxCreditsAskForOne',
                labels: taxCreditsLabels,
                notApplicableKey: modelSettings.taxCredits.no,
                isShowingCheckedOnly: isAdmin,
              },
              infoCta: getInfoCtas(
                Object.keys(taxCreditsLabels).map(creditKey => {
                  if (creditKey === 'no') return {}

                  const keysWithoutWhere = ['homeSupport', 'volunteer']
                  const localeKey = `taxCredits.${creditKey}`
                  return {
                    label: taxCreditsLabels[creditKey],
                    details: localeKey,
                    where: keysWithoutWhere.includes(creditKey) ? undefined : localeKey,
                    isCheckbox: true,
                  }
                })
              ),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'taxCreditsAskForOne',
                values: value => value?.split(',').includes(modelSettings.taxCredits.homeSupport),
              },
              components: [
                {
                  is: Select,
                  question: getQuestionTextWithYear('taxCreditsHomeSupportAdvancePayments'),
                  props: {
                    id: 'taxCreditsHomeSupportAdvancePayments',
                    label: '',
                    options: YES_NO_OPTIONS,
                  },
                },
                {
                  is: Select,
                  question: getQuestionText('taxCreditsWholeYearSameAddress'),
                  props: {
                    id: 'taxCreditsWholeYearSameAddress',
                    label: '',
                    options: YES_NO_OPTIONS,
                  },
                },
              ],
            },
            ...(childrenList.length && personType !== PERSON_TYPES.child
              ? [
                  {
                    isOpen: false,
                    openOn: {
                      id: 'taxCreditsAskForOne',
                      values: value =>
                        value?.split(',').includes(modelSettings.taxCredits.disabledPerson),
                    },
                    components: [
                      {
                        is: Select,
                        question: getQuestionText('taxCreditsWhoIsDisabled'),
                        props: {
                          id: 'taxCreditsWhoIsDisabled',
                          label: '',
                          options: [
                            { value: undefined, text: '' },
                            ...[...selfList, ...childrenList].map(c => ({
                              value: c.personKey,
                              text: c.name,
                            })),
                          ],
                        },
                      },
                    ],
                  },
                ]
              : []),
            {
              isOpen: false,
              openOn: {
                id: 'taxCreditsAskForOne',
                values: value => value?.split(',').includes(modelSettings.taxCredits.remoteAreas),
              },
              components: [
                {
                  is: Select,
                  question: getQuestionTextWithYear('taxCreditsStayedInRemoteAreaAllYear'),
                  props: {
                    id: 'taxCreditsStayedInRemoteAreaAllYear',
                    label: '',
                    options: YES_NO_OPTIONS,
                  },
                },
              ],
            },
            {
              isOpen: false,
              openOn: {
                id: 'taxCreditsStayedInRemoteAreaAllYear',
                values: ['0'],
              },
              components: [
                {
                  is: DateInput,
                  props: {
                    id: 'taxCreditsBeginningOfStay',
                    label: getQuestionText('taxCreditsBeginningOfStay'),
                    specificYearOnly: Number(currentReportYear),
                  },
                },
                {
                  is: DateInput,
                  props: {
                    id: 'taxCreditsEndOfStay',
                    label: getQuestionText('taxCreditsEndOfStay'),
                    specificYearOnly: Number(currentReportYear),
                  },
                },
              ],
            },
            {
              isOpen: false,
              openOn: {
                id: 'taxCreditsAskForOne',
                values: value =>
                  value?.split(',').includes(modelSettings.taxCredits.movingExpenses),
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('taxCreditsMovingCloserToWork'),
                  props: {
                    id: 'taxCreditsMovingCloserToWork',
                    label: '',
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('taxCreditsMovingCloserToWork'),
                      details: 'taxCreditsMovingCloserToWork',
                      where: 'taxCreditsMovingCloserToWork',
                    },
                  ]),
                },
              ],
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.generalQuestions]: {
      titleLocalePath: 'account.report.questions.titles.generalQuestions',
      questions: {
        telecommuting: {
          question: getQuestionText('telecommuting', { year: currentReportYear }),
          isApplicable: () => isApplicableForYear.telecommuting,
          components: [
            {
              is: Select,
              props: {
                id: 'telecommuting',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('telecommuting', { year: currentReportYear }),
                  details: 'telecommuting',
                },
              ]),
            },
          ],
        },
        medicalFees: {
          question: getQuestionText('medicalFees'),
          components: [
            {
              is: Select,
              props: {
                id: 'medicalFees',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('medicalFees'),
                  details: 'medicalFees',
                  where: 'medicalFees',
                },
              ]),
            },
          ],
        },
        donations: {
          question: getQuestionText('donations'),
          components: [
            {
              is: Select,
              props: {
                id: 'donations',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('donations'),
                  details: 'donations',
                  where: 'donations',
                },
              ]),
            },
          ],
        },
        propertyOutsideCanada: {
          question: getQuestionText('propertyOutsideCanada'),
          components: [
            {
              is: Select,
              props: {
                id: 'propertyOutsideCanada',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('propertyOutsideCanada'),
                  details: 'propertyOutsideCanada',
                  where: 'propertyOutsideCanada',
                },
              ]),
            },
          ],
        },
        associationContribution: {
          question: getQuestionText('associationContribution'),
          components: [
            {
              is: Select,
              props: {
                id: 'associationContribution',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('associationContribution'),
                  details: 'associationContribution',
                  where: 'associationContribution',
                },
              ]),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'associationContribution',
                values: ['1'],
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('associationContributionReimbursed'),
                  props: {
                    id: 'associationContributionReimbursed',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                },
              ],
            },
          ],
        },
        spouseOrChildSupportPayments: {
          question: getQuestionText('spouseOrChildSupportPayments'),
          isApplicable: isApplicable.spouseOrChildSupportPayments,
          components: [
            {
              is: Select,
              props: {
                id: 'spouseOrChildSupportPayments',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('spouseOrChildSupportPayments'),
                  details: 'spouseOrChildSupportPayments',
                },
              ]),
            },
          ],
        },
        buyFirstHome: {
          question: getQuestionTextWithYear('buyFirstHome'),
          components: [
            {
              is: Select,
              props: {
                id: 'buyFirstHome',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionTextWithYear('buyFirstHome'),
                  details: 'buyFirstHome',
                  where: 'buyFirstHome',
                },
              ]),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'buyFirstHome',
                values: ['1'],
              },
              components: [
                {
                  is: Select,
                  question: getQuestionText('buyFirstHomeAlone'),
                  props: {
                    id: 'buyFirstHomeAlone',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                  infoCta: getInfoCtas([
                    {
                      label: getQuestionText('buyFirstHomeAlone'),
                      details: 'buyFirstHomeAlone',
                      where: 'buyFirstHomeAlone',
                    },
                  ]),
                },
              ],
            },
          ],
        },
        sellPrincipalResidence: {
          question: getQuestionText('sellPrincipalResidence'),
          components: [
            {
              is: Select,
              props: {
                id: 'sellPrincipalResidence',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('sellPrincipalResidence'),
                  details: 'sellPrincipalResidence',
                  where: 'sellPrincipalResidence',
                },
              ]),
            },
          ],
        },
        sellOtherResidence: {
          question: getQuestionText('sellOtherResidence'),
          components: [
            {
              is: Select,
              props: {
                id: 'sellOtherResidence',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('sellOtherResidence'),
                  details: 'sellOtherResidence',
                  where: 'sellOtherResidence',
                },
              ]),
            },
          ],
        },
        hadCryptoAssets: {
          question: getQuestionText('hadCryptoAssets'),
          isApplicable: isApplicable.hadCryptoAssets,
          components: [
            {
              is: Select,
              props: {
                id: 'hadCryptoAssets',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: YES_NO_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('hadCryptoAssets'),
                  details: 'hadCryptoAssets',
                  where: 'hadCryptoAssets',
                },
              ]),
            },
          ],
          accordions: [
            {
              isOpen: false,
              openOn: {
                id: 'hadCryptoAssets',
                values: ['1'],
              },
              components: [
                {
                  is: Select,
                  question: getQuestionTextWithYear('hadCryptoAssetsByEndOfTaxYear'),
                  props: {
                    id: 'hadCryptoAssetsByEndOfTaxYear',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                },
                {
                  is: Select,
                  question: getQuestionText('hadGainOrLossFromCryptoAssets'),
                  props: {
                    id: 'hadGainOrLossFromCryptoAssets',
                    label: this.$t('globals.select'),
                    isHidingLabelOnValue: true,
                    options: YES_NO_OPTIONS,
                  },
                },
              ],
            },
          ],
        },
        investmentPlatformInterestLevel: {
          question: getQuestionText('investmentPlatformInterestLevel'),
          isApplicable: isApplicable.investmentPlatformInterestLevel,
          components: [
            {
              is: Select,
              props: {
                id: 'investmentPlatformInterestLevel',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: INVESTMENT_PLATFORM_INTEREST_OPTIONS,
              },
            },
          ],
        },
      },
    },
    [modelSettings.questionCategories.otherInformation]: {
      titleLocalePath: 'account.report.questions.titles.otherInformation',
      questions: {
        preferredContactMethod: {
          question: getQuestionText('preferredContactMethod'),
          components: [
            {
              is: Select,
              props: {
                id: 'preferredContactMethod',
                label: this.$t('globals.select'),
                isHidingLabelOnValue: true,
                options: PREFERRED_CONTACT_METHOD_OPTIONS,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('preferredContactMethod'),
                  details: 'preferredContactMethod',
                },
              ]),
            },
          ],
        },
        otherInfo: {
          question: getQuestionText('otherInformation'),
          components: [
            {
              is: Input,
              props: {
                id: 'otherInformation',
                isTextarea: true,
                isOptional: true,
              },
              infoCta: getInfoCtas([
                {
                  label: getQuestionText('otherInformation'),
                  details: 'otherInformation',
                },
              ]),
            },
          ],
        },
      },
    },
  }
  return fields
}

export function getRenderedQuestionFields(questionFields) {
  const renderedQuestionFields = {}
  for (const questionCategory in questionFields) {
    const settings = questionFields[questionCategory]
    if (typeof settings.if === 'undefined' || this[settings.if]) {
      renderedQuestionFields[questionCategory] = settings
    }
  }
  return renderedQuestionFields
}
