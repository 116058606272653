import models from 'acces-impot-settings-report'
import { STEP_KEYS } from '@/store/report/report-settings'
import { personalizeTranslation } from '@/helpers/i18n'
import { capitalizeFirstLetter } from '@/helpers/string'
import {
  getLocaleKeyOfQuestion,
  getLocaleKeyOfQuestionValue,
} from '../ReportRelevantQuestions/report-relevant-questions-settings'

/**
 * @function addToDocumentTypesToUpload
 *
 * @param {object} documentTypesToUpload - Document types that we ask the user to provide
 * @param {object} documentTypesToUpload.{documentTypesToUploadKey} - To avoid duplicates
 * @param {array} documentTypesToUpload.{documentTypesToUploadKey}.keys - Array of document
 *   types to ask for.
 * @param {boolean} documentTypesToUpload.{documentTypesToUploadKey}.isOptional - If true, it's
 *   optional to provide the documents, otherwise, it's required to provide at least one of the
 *   document.
 * @param {object} documentTypesToUpload.{documentTypesToUploadKey}.reason
 * @param {string} documentTypesToUpload.{documentTypesToUploadKey}.reason.key - report data key
 * @param {string} documentTypesToUpload.{documentTypesToUploadKey}.reason.value - report data value
 */
function addToDocumentTypesToUpload(documentTypesToUpload, obj) {
  obj.isOptional = !!obj.isOptional
  const _ = '___'
  const documentTypesToUploadKey =
    `${_}${obj.keys.join(_)}` +
    `${_}${obj.isOptional ? 0 : 1}` +
    `${_}${obj.reason.key}${_}${obj.reason.value}`
  documentTypesToUpload[documentTypesToUploadKey] = obj
}

/**
 * @function addToDocumentReasonsToAsk
 *
 * @param {object} documentReasonsToAsk - Reasons why we ask for a document
 * @param {object} documentReasonsToAsk.{documentType} - Object of report data keys
 * @param {array} documentReasonsToAsk.{documentType}.{reportDataKey} - Array of report data values
 * @example { t4a: ['incomeSources', 'studentScholarships'] }
 *
 * @param {string} documentType
 *
 * @param {object} reason
 * @param {string} reason.key - report data key
 * @param {string} reason.value - report data value
 */
export function addToDocumentReasonsToAsk(documentReasonsToAsk, documentType, reason) {
  documentReasonsToAsk[documentType] = documentReasonsToAsk[documentType] || {}
  documentReasonsToAsk[documentType][reason.key] =
    documentReasonsToAsk[documentType][reason.key] || []
  documentReasonsToAsk[documentType][reason.key].push(reason.value)
}

function addRequiredDocuments(value, reportDataKey, requiredDocumentTypes, { isOptional } = {}) {
  const documentTypesToUpload = {}
  const documentReasonsToAsk = {}
  const reason = { key: reportDataKey, value }

  if (isOptional) {
    addToDocumentTypesToUpload(documentTypesToUpload, {
      keys: requiredDocumentTypes,
      reason,
      isOptional,
    })
  }

  requiredDocumentTypes.forEach(documentType => {
    if (!isOptional) {
      addToDocumentTypesToUpload(documentTypesToUpload, { keys: [documentType], reason })
    }
    addToDocumentReasonsToAsk(documentReasonsToAsk, documentType, reason)
  })
  return { documentTypesToUpload, documentReasonsToAsk }
}

function addOptionalDocuments(value, reportDataKey, requiredDocumentTypes) {
  return addRequiredDocuments(value, reportDataKey, requiredDocumentTypes, { isOptional: true })
}

function isValueDefined(value) {
  return value !== '' && value !== null && value !== undefined
}

const DOCUMENT_TO_UPLOAD_GETTERS = {
  personalDetails: {},

  relevantQuestions: {
    solidarityTaxCreditOwnOrRent(value, reportDataKey) {
      if (value === models.solidarityTaxCreditOwnOrRent.rent) {
        return addRequiredDocuments(value, reportDataKey, [models.documentTypes.rl_31])
      }
    },

    incomeSources(value, reportDataKey) {
      if (!value) return

      const documentTypesToUpload = {}
      const documentReasonsToAsk = {}
      const d = models.documentTypes
      const documentMap = {
        [models.incomeSources.employment]: [d.t4, d.rl_1],
        [models.incomeSources.retirement]: [[d.t4a, d.t4rif, d.t4a_oas, d.t4a_p, d.t4rsp]],
        [models.incomeSources.retirementPsv]: [d.t4a_oas],
        [models.incomeSources.retirementRrq]: [d.t4a_p, d.rl_2],
        [models.incomeSources.retirementPension]: [d.t4a, d.rl_2],
        [models.incomeSources.retirementRrif]: [d.t4rif, d.rl_2],
        [models.incomeSources.investment]: [d.t5, d.rl_3],
        [models.incomeSources.stocksSecuritiesCryptocurrencies]: [d.t5008_r18],
        [models.incomeSources.socialAssistance]: [d.t5007, d.rl_5],
        [models.incomeSources.cnesst]: [d.t5007, d.rl_5],
        [models.incomeSources.ivac]: [d.t5007, d.rl_5],
        [models.incomeSources.employmentInsurance]: [d.t4e],
        [models.incomeSources.qpip]: [d.t4e, d.rl_6],
        [models.incomeSources.taxableSalaryInsurance]: [[d.t4, d.t4a], d.rl_1],
        [models.incomeSources.saaq]: [d.rl_5],
        [models.incomeSources.trustIncome]: [d.t3],
        [models.incomeSources.cerb]: [d.t4a, d.rl_1],
        [models.incomeSources.cesb]: [d.t4a, d.rl_1], // TODO: remove eventually
        [models.incomeSources.foreignIncome]: [d.foreignIncomeDetails],
      }
      const optionalMap = {
        [models.incomeSources.taxableSalaryInsurance]: [false, true],
      }
      value.split(',').forEach(source => {
        ;(documentMap[source] || []).forEach((docs, docsIndex) => {
          if (typeof docs === 'string') docs = [docs]

          const reason = {
            key: reportDataKey,
            value: source,
          }
          addToDocumentTypesToUpload(documentTypesToUpload, {
            keys: docs,
            isOptional: !!(optionalMap[source] && optionalMap[source][docsIndex]),
            reason,
          })
          docs.forEach(doc => addToDocumentReasonsToAsk(documentReasonsToAsk, doc, reason))
        })
      })
      return { documentTypesToUpload, documentReasonsToAsk }
    },

    incomeSelfEmployedWithExpenses(value) {
      if (isValueDefined(value)) {
        const docType =
          models.documentTypes[
            Number(value) === 1 ? 'selfEmployedInfoWithExpenses' : 'selfEmployedInfoWithoutExpenses'
          ]
        return addRequiredDocuments(true, 'incomeSelfEmployed', [docType])
      }
    },

    incomeSelfEmployedInstallmentPayments(value) {
      if (Number(value) !== 1) return

      const documentTypes = [
        models.documentTypes.selfEmployedFederalInstallment,
        models.documentTypes.selfEmployedProvincialInstallment,
      ]
      return addRequiredDocuments(true, 'incomeSelfEmployedInstallmentPayments', documentTypes)
    },

    rentalBuildingCount(value) {
      if (isValueDefined(value)) {
        const docType = models.documentTypes.rentalIncomeDetails
        return addRequiredDocuments(String(value), 'rentalBuildingCount', [docType])
      }
    },

    rrspLast60Days(value, reportDataKey) {
      if (Number(value) !== 1) return

      const documentTypes = [
        models.documentTypes.rrspContributionReceipt,
        models.documentTypes.rrspContributionReceiptFirst60days,
      ]
      const documentTypesToUpload = {}
      const documentReasonsToAsk = {}
      const reason = { key: reportDataKey, value }

      documentTypes.forEach((documentType, index) => {
        addToDocumentTypesToUpload(documentTypesToUpload, {
          keys: [documentType],
          isOptional: index > 0,
          reason,
        })
        addToDocumentReasonsToAsk(documentReasonsToAsk, documentType, reason)
      })
      return { documentTypesToUpload, documentReasonsToAsk }
    },

    rrspContribution(value, key) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, key, [models.documentTypes.rrspContributionReceipt])
      }
    },

    rrspContributionLast60Days(value, key) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, key, [
          models.documentTypes.rrspContributionReceiptFirst60days,
        ])
      }
    },

    rrspFhsaContribution(value, reportDataKey) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, reportDataKey, [
          models.documentTypes.fhsaContributionReceipt,
        ])
      }
    },

    rrspWithdrawUnderHbpLlp(value, reportDataKey) {
      if (value !== models.withdrawUnderHbpLlp.no) {
        return addRequiredDocuments(value, reportDataKey, [
          models.documentTypes.federalNoticeOfAssessment,
        ])
      }
    },

    rrspWithdrawOtherSituation(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.t4rsp, models.documentTypes.rl_2]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    childrenChildcareExpenses(value, reportDataKey) {
      if (Number(value) !== 1) return

      const d = models.documentTypes
      const requiredDocumentTypes = [d.rl_24]
      const { documentTypesToUpload, documentReasonsToAsk } = addRequiredDocuments(
        value,
        reportDataKey,
        requiredDocumentTypes
      )
      const optionalDocumentTypes = [d.childcareReceipt]
      const reason = { key: reportDataKey, value }

      addToDocumentTypesToUpload(documentTypesToUpload, {
        keys: optionalDocumentTypes,
        isOptional: true,
        reason,
      })
      optionalDocumentTypes.forEach(documentType => {
        addToDocumentReasonsToAsk(documentReasonsToAsk, documentType, reason)
      })
      return { documentTypesToUpload, documentReasonsToAsk }
    },

    childrenAdvancePayments(value, reportDataKey) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, reportDataKey, [models.documentTypes.rl_19])
      }
    },

    childrenActivityCosts(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.childrenActivitiesReceipt]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    studentWereYou(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.t2202, models.documentTypes.rl_8]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    studentScholarships(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.t4a, models.documentTypes.rl_1]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    studentWithdrawFromResp(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.t4a, models.documentTypes.rl_1]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    studentRepaidInterestOnLoan(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.studentLoanStatement]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    studentTuitionFeesPreviousYear(value, reportDataKey) {
      if (Number(value) !== 0) {
        const documentTypes = [
          models.documentTypes.federalNoticeOfAssessment,
          models.documentTypes.provincialNoticeOfAssessment,
        ]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    taxCreditsAskForOne(value, reportDataKey) {
      if (!value) return
      if (value.split(',').includes(models.taxCredits.renoVert)) {
        return addRequiredDocuments(value, reportDataKey, [models.documentTypes.tp_1029])
      }
    },

    taxCreditsHomeSupportAdvancePayments(value, reportDataKey) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, reportDataKey, [models.documentTypes.rl_19])
      }
    },

    propertyOutsideCanada(value, reportDataKey) {
      if (Number(value) === 1) {
        return addRequiredDocuments(value, reportDataKey, [models.documentTypes.t1135])
      }
    },

    associationContribution(value, reportDataKey) {
      if (Number(value) === 1) {
        const documentTypes = [models.documentTypes.associationContributionReceipt]
        return addRequiredDocuments(value, reportDataKey, documentTypes)
      }
    },

    hadCryptoAssets(value, reportDataKey, personData) {
      if (isValueDefined(value)) {
        const hasAnsweredYesForAll = [
          'hadCryptoAssets',
          'hadCryptoAssetsByEndOfTaxYear',
          'hadGainOrLossFromCryptoAssets',
        ].every(cryptoKey => {
          return cryptoKey in personData && personData[cryptoKey]
        })

        if (hasAnsweredYesForAll) {
          const documentTypes = [models.documentTypes.t5008, models.documentTypes.rl_18]
          return addRequiredDocuments(value, reportDataKey, documentTypes)
        }
      }
    },
  },
}
const DEFAULT_GETTER_RESPONSE = { documentTypesToUpload: {}, documentReasonsToAsk: {} }

export function getAllDocumentsToUploadAndReasons(reportData, personKey) {
  const stepKey = STEP_KEYS.relevantQuestions
  const relevantQuestions = reportData[stepKey][personKey] || {}
  const allDocumentTypesToUpload = {}
  const allDocumentReasonsToAsk = {}

  Object.keys(relevantQuestions).forEach(questionKey => {
    const response = getDocumentToUploadAndReason(reportData, stepKey, personKey, questionKey)
    const { documentTypesToUpload, documentReasonsToAsk } = response

    Object.assign(allDocumentTypesToUpload, documentTypesToUpload)

    Object.keys(documentReasonsToAsk).forEach(documentType => {
      Object.keys(documentReasonsToAsk[documentType]).forEach(reasonKey => {
        documentReasonsToAsk[documentType][reasonKey].forEach(reasonValue => {
          const reason = { key: reasonKey, value: reasonValue }
          addToDocumentReasonsToAsk(allDocumentReasonsToAsk, documentType, reason)
        })
      })
    })
  })
  return {
    documentTypesToUpload: allDocumentTypesToUpload,
    documentReasonsToAsk: allDocumentReasonsToAsk,
  }
}

export function getDocumentToUploadAndReason(reportData, stepKey, personKey, reportDataKey) {
  const stepMap = DOCUMENT_TO_UPLOAD_GETTERS[stepKey] || {}
  const getter = stepMap[reportDataKey] || (() => DEFAULT_GETTER_RESPONSE)
  const personData = reportData[stepKey][personKey]
  const value = personData[reportDataKey]
  const response = getter(value, reportDataKey, personData) || DEFAULT_GETTER_RESPONSE

  if (process.env.TARGET_ENV === 'development') {
    Object.values(response.documentTypesToUpload || {}).forEach(docToUpload => {
      if (!response.documentReasonsToAsk) {
        throw new Error(
          'The property "documentReasonsToAsk" is missing in response of "getDocumentToUploadAndReason"'
        )
      } else {
        docToUpload.keys.forEach(docKey => {
          if (!response.documentReasonsToAsk[docKey]) {
            throw new Error(
              `The document type "${docKey}" is set in "documentTypesToUpload" but missing in "documentReasonsToAsk"`
            )
          }
        })
      }
    })
  }
  return response
}

export function documentTypesToAskFor(documentTypesToUpload = {}) {
  const documentTypesToAskFor = {}

  Object.values(documentTypesToUpload).forEach(settings => {
    settings.keys.forEach(docKey => {
      if (documentTypesToAskFor[docKey] !== false) {
        documentTypesToAskFor[docKey] = !!settings.isOptional
      }
    })
  })
  return documentTypesToAskFor
}

export function getSpecialCasesErrors(i18n, documentTypesToUpload, providedDocumentTypes) {
  const localePrefix = 'account.report.documents.specialCasesErrors'
  const errorMap = {
    incomeSources: `${localePrefix}.incomeSources`,
  }
  const errors = []

  Object.values(documentTypesToUpload)
    .filter(d => d.keys.length > 1)
    .forEach(settings => {
      if (settings.keys.some(documentType => providedDocumentTypes.includes(documentType))) return

      const { reason } = settings
      const errorLocaleKey = errorMap[reason.key]
      if (!errorLocaleKey) return

      const documents = settings.keys
        .map(documentType => i18n.t(`documentTypes.${documentType}`))
        .join(', ')
        .replace(/,([^,]+)$/, ` ${i18n.t('globals.or')}$1`)

      const value = i18n.t(`account.report.${reason.key}.${reason.value}`)
      errors.push(i18n.t(errorLocaleKey, { value, documents }))
    })

  return errors
}

export function getInfoCtaMoreDetailsText({
  documentReasonsToAsk,
  documentType,
  personType,
  personGender,
  reportYear,
  isAdminSection = false,
}) {
  const reasonsToAsk = documentReasonsToAsk[documentType] || {}
  const questionParagraphs = []

  const personalizeText = localeKey =>
    personalizeTranslation.call(this, {
      key: localeKey,
      personType,
      personGender,
      options: getYearOptions(reportYear),
    })

  for (const questionKey in reasonsToAsk) {
    const question = personalizeText(getLocaleKeyOfQuestion(questionKey))
    let paragraph = `${question}\n<ul>`

    reasonsToAsk[questionKey].forEach(questionValue => {
      const answer = personalizeText(getLocaleKeyOfQuestionValue(questionKey, questionValue))

      paragraph += `<li>${answer}</li>`
    })
    paragraph += '</ul>'
    questionParagraphs.push(paragraph)
  }
  const localeKey = isAdminSection ? 'adminWhyDoWeAsk' : 'whyDoWeAsk'

  return {
    moreDetailsHeadingText: this.$tc(
      `account.report.overlay.${localeKey}`,
      questionParagraphs.length
    ),
    moreDetailsText: questionParagraphs.join('\n\n'),
  }
}

export function getInfoCtaWhereToFindText({ documentType, personType, personGender, reportYear }) {
  const returnedValue = {
    whereToFindHeadingText: this.$t('account.report.overlay.whereToFindDocument'),
  }

  ;['details', 'where'].forEach(sectionKey => {
    const localeKey = `reportFieldDetails.documents.${documentType}.${sectionKey}`
    if (this.$t(localeKey) === localeKey) return

    const personalizedText = personalizeTranslation.call(this, {
      key: localeKey,
      personType,
      personGender,
      options: getYearOptions(reportYear),
    })
    const prop = sectionKey === 'details' ? 'moreDetailsTextExtra' : 'whereToFindText'

    returnedValue[prop] =
      typeof personalizedText === 'string' && personalizedText !== localeKey ? personalizedText : ''
  })
  return returnedValue
}

export function translateDocumentType({ documentType, reportYear }) {
  return capitalizeFirstLetter(this.$t(`documentTypes.${documentType}`, getYearOptions(reportYear)))
}

export function getYearOptions(reportYear) {
  const previousYear = Number(reportYear) - 1
  const oneYearLater = Number(reportYear) + 1
  return { year: reportYear, previousYear, oneYearLater }
}

export function getCurrentProvidedMissingInfos({ requestCreatedAt, providedMissingInfos }) {
  const getDefault = () => ({ time: undefined, infos: {} })

  if (!providedMissingInfos || !Object.keys(providedMissingInfos).length) {
    return getDefault()
  }
  const valid = v => (v && typeof v === 'object' ? v : {})

  const infos = providedMissingInfos[String(requestCreatedAt)]
  if (infos) return { time: Number(requestCreatedAt), infos: valid(infos) }

  const infoEntries = Object.entries({ ...providedMissingInfos })
  infoEntries.sort(([t1], [t2]) => {
    const time1 = Number(t1)
    const time2 = Number(t2)
    return time1 < time2 ? 1 : time1 > time2 ? -1 : 0
  })
  const [latestTime, latestInfos] = infoEntries[0]
  const latestTimeNum = Number(latestTime)

  return latestTimeNum >= requestCreatedAt
    ? { time: latestTimeNum, infos: valid(latestInfos) }
    : getDefault()
}
