<template>
  <div
    :class="[
      $style.infoPanel,
      $style[theme],
      {
        [$style.hasNoIcon]: hasNoIcon,
        [$style.small]: hasSmallPaddingVertical,
        [$style.darkColor]: hasDarkTextColor,
      },
    ]"
  >
    <component :is="iconComponent" v-if="!hasNoIcon" :class="$style.icon" />
    <slot />
  </div>
</template>

<script>
import IconGreenCheckCircle from '@/components/_icons/IconGreenCheckCircle.svg'
import IconInfoCircle from '@/components/_icons/IconInfoCircle.svg'
import IconWarning from '@/components/_icons/IconWarning.svg'

export const INFO_PANEL_THEMES = {
  info: 'info',
  positive: 'positive',
  warning: 'warning',
}

export default {
  name: 'InfoPanel',

  props: {
    theme: {
      type: String,
      default: INFO_PANEL_THEMES.warning,
      validator(value) {
        return Object.values(INFO_PANEL_THEMES).includes(value)
      },
    },

    hasNoIcon: {
      type: Boolean,
      default: false,
    },

    hasSmallPaddingVertical: {
      type: Boolean,
      default: false,
    },

    hasDarkTextColor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconComponent() {
      const map = {
        [INFO_PANEL_THEMES.info]: IconInfoCircle,
        [INFO_PANEL_THEMES.positive]: IconGreenCheckCircle,
        [INFO_PANEL_THEMES.warning]: IconWarning,
      }
      return map[this.theme] || map[INFO_PANEL_THEMES.warning]
    },
  },
}
</script>

<style lang="scss" module>
$iconSize: 32px;
$iconPositiveSize: 28px;

.infoPanel {
  position: relative;
  padding: 18px 18px 18px 52px;
  color: $color-high-contrast-text;

  @include min-screen($mq-small) {
    padding: 20px 20px 20px 60px;
  }

  &.small {
    padding-top: 13px;
    padding-bottom: 13px;

    @include min-screen($mq-small) {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  &.hasNoIcon {
    padding-left: 18px;

    @include min-screen($mq-small) {
      padding-left: 20px;
    }
  }

  &.darkColor {
    color: $color-grey-2-5;
  }
}

.info {
  background-color: $color-brand-blue-grey-bg-light;
}

.positive {
  background-color: $color-brand-green-bg-light;
}

.warning {
  background-color: $color-brand-yellow-light-bg;
}

.icon {
  position: absolute;
  opacity: 0.8;

  .info &,
  .warning & {
    width: $iconSize;
    height: $iconSize;
  }

  .info & {
    top: 14px;
    left: 11px;
    color: darken($color-brand-blue-grey, 20);

    @include min-screen($mq-small) {
      top: 17px;
      left: 16px;
    }
  }

  .info.small & {
    top: 11px;

    @include min-screen($mq-small) {
      top: 12px;
    }
  }

  .positive & {
    top: 16px;
    left: 13px;
    width: $iconPositiveSize;
    height: $iconPositiveSize;
    color: $color-brand-green;

    @include min-screen($mq-small) {
      top: 19px;
      left: 18px;
    }
  }

  .positive.small & {
    top: 13px;

    @include min-screen($mq-small) {
      top: 14px;
    }
  }

  .warning & {
    top: 12px;
    left: 10px;
    color: $color-brand-yellow;

    @include min-screen($mq-small) {
      top: 15px;
      left: 15px;
    }
  }

  .warning.small & {
    top: 11px;

    @include min-screen($mq-small) {
      top: 12px;
    }
  }
}
</style>
